<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Create DSP Pop Feed</strong>
            </div>
            <div class="text-muted">Create new DSP Pop Feed</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Advertiser">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Owner advertiser's ID.</span>
                      </template>
                      <a-select
                        v-decorator="['advertiser', { initialValue: initialValue, rules: [{ required: true, message: 'Please select advertiser.' }] }]"
                        placeholder="Please select an advertiser" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option v-for="obj in advertiser.list.filter(ele => ele.Status === 0)" :key="obj.Id">
                          [{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>DSP Pop Feed's name.</span>
                      </template>
                      <a-input type="text" placeholder="DSP Pop Feed Name" v-decorator="['name', {
              rules: [
                { required: true, message: 'Name is required.' },
                { max: 64, message: 'Maximum 64 characters allowed.' }
              ]
            }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>DSP Pop Feed's description.</span>
                      </template>
                      <a-input placeholder="Description" v-decorator="['description', {
              rules: [
                { max: 512, message: 'Maximum 512 characters allowed.' }
              ]
            }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impression Tracking Method">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>How advertiser tracks impressions for DSP Pop Feed. System will fire chosen pixel when
                          impression occurs (nurl or burl). If Markup Display value is chosen, the system will not
                          notify advertiser's server and advertiser will count impressions when their markup is
                          displayed. This value has an effect only for the impressions from connected Banner and Video
                          Zones, but not from RTB Zones.</span>
                      </template>
                      <a-select v-decorator="['trackingMethod', { initialValue: 2 }]"
                        placeholder="Impression Tracking Method" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option :value="0">
                          nurl
                        </a-select-option>
                        <a-select-option :value="1">
                          burl
                        </a-select-option>
                        <a-select-option :value="2">
                          Markup Display
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item> -->
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. QPS">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum queries per second - Maximum number of requests to make to the DSP Pop Feed in one
                          second. Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Max. QPS"
                        v-decorator="['maxQps', { initialValue: 0 }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Auto Optimize">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Enable Automatic requests optimization.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="autoOpt" />
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <div class="w-100">
              <a-form-item :label-col="labelColUrl" :wrapper-col="wrapperColUrl" label="Feed URL">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>DSP Pop Feed's URL.</span>
                    </template>
                    <a-input type="url" placeholder="Feed URL" v-decorator="['feedUrl', {
              rules: [
                { required: true, message: 'Feed URL is required.' },
                { max: 256, message: 'Maximum 256 characters allowed.' }
              ]
            }]">
                                <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{'max-width': '200%'}">
                      <template slot="title">
                        <b>{cc}</b> - Lowercase country code will be replaced.<br>
                        <b>{CC}</b> - Uppercase country code will be replaced.<br>
                        <b>{Country}</b> - Capitalized country name will be replaced.<br>
                        <b>{ip}</b> - IP will be replaced.<br>
                        <b>{ua}</b> - User-agent string will be replaced.<br>
                        <b>{width}</b> - Width will be replaced.<br>
                        <b>{height}</b> - Height will be replaced.<br>
                        <b>{linearity}</b> - Linearity will be replaced.<br>
                        <b>{site_url}</b> - Site URL will be replaced.<br>
                        <b>{app_bundle}</b> - App Bundle will be replaced.<br>
                        <b>{app_name}</b> - App Name will be replaced.<br>
                        <b>{app_store_url}</b> - App Store URL will be replaced.<br>
                        <b>{bidfloor}</b> - Minimal CPM for the request.<br>
                        <b>{minduration}</b> - Minimal Video Ad duration in seconds.<br>
                        <b>{maxduration}</b> - Maximal Video Ad duration in seconds.<br>
                        <b>{category}</b> - Advertising category according to IAB classification.<br>
                        <b>{cb}</b> - Cache buster: a uniquely generated character sequence for each link in order to prevent page caching.<br>
                        <b>{timestamp}</b> - Timestamp generated at the time of transaction.<br>
                        <b>{schain}</b> - Serialized schain parameter.<br>
                        <b>{lat}</b> - Latitude will be replaced.<br>
                        <b>{lon}</b> - Longitude will be replaced.<br>
                        <b>{ifa}</b> - Identifier for Advertisers will be replaced.<br>
                        <b>{cid}</b> - Click ID will be replaced.<br>
                        <b>{paln}</b> - PALN weill be replaced.<br>
                        <b>{givn}</b> - GIVN weill be replaced.<br>
                      </template>
                      <a-icon type="code"/>
                    </a-tooltip>
                    </a-input>
                  </a-tooltip>
                </a-form-item></div>
              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Requests Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of requests to make per day. The system will even out distribution of requests
                          throughout the day, ie. "day shaping". Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Requests Daily Limit"
                        v-decorator="['requestsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout the day,
                          ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Limit Per IP">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Daily limit of impressions per IP address. Set to 0 for unlimited impressions per IP
                          address.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsLimitPerIp', { initialValue: 0, rules: [{ validator: impressionsValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of impressions
                          throughout the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Revenue Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to earn from advertiser. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" step="0.0001" placeholder="Revenue Daily Limit"
                        v-decorator="['revenueDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Supply Filters</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. Bidfloor">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>DSP Pop Feed will not participate in auction if connected supply zone has Bidfloor greater
                          than this value.</span>
                      </template>
                      <a-input type="float" min="0" max="20" step="0.0001" placeholder="Max. Bidfloor"
                        v-decorator="['maxBidfloor', { initialValue: 20, rules: [{ validator: cpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Min. Tmax">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>DSP Pop Feed will not participate in auction if connected supply zone has Tmax less than
                          this value.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Min. Tmax"
                        v-decorator="['minTmax', { initialValue: 10 }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. IVT">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>DSP Pop Feed will not participate in auction if connected supply zone has inventory with
                          IVT greater than this value.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%" v-decorator="['maxIvt', { initialValue: 10 }]"
                        placeholder="Max. IVT">
                      </a-input>
                      <!-- <a-input  /> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Max. SIVT">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>DSP Pop Feed will not participate in auction if connected supply zone has inventory with
                          SIVT greater than this value.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%"
                        v-decorator="['maxSivt', { initialValue: 10 }]" placeholder="Max. SIVT">
                      </a-input>
                      <!-- <a-input  /> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Domains/Brands List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow publisher domains or brand names.</span>
                      </template>
                      <a-select v-decorator="['domainsBrandsListMode', { initialValue: 0 }]"
                        placeholder="Domains/Brands List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Domains/Brands List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Domain Lists' IDs to block or allow.</span>
                      </template>
                      <a-select v-decorator="['domainsBrandsList', { initialValue: [] }]"
                        placeholder="Please Select Domains/Brands Lists" :showSearch="true" :filterOption="true"
                        mode="multiple" optionFilterProp="children">
                        <a-select-option v-for="obj in activeDomainLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Non Empty Referer">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Ad impression or click with no referer will be filtered.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled"
                        v-model="nonEmptyRefererEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Referer Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Referer in ad impression or click and site URL should match.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled"
                        v-model="refererMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="IP Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Real user's IP address in ad impression or click and user IP parameter should
                          match.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="ipMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="UA Match">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Real user's User Agent in ad impression or click and user agent parameter should
                          match.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="uaMatchEnabled" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Pub ID List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow Pub IDs.</span>
                      </template>
                      <a-select v-decorator="['pubIdListMode', { initialValue: 0 }]" placeholder="Pub ID List Mode"
                        :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Pub ID List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Pub IDs to block or allow.</span>
                      </template>
                      <div class="list-flex">
                        <a-select v-model="pubIdList" placeholder="Pub ID List" :showSearch="true" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('pubIds')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="IP Blacklists">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of IP Lists to use for IP block. Only "Not Global" IP Lists can be chosen.</span>
                      </template>
                      <a-select v-decorator="['ipBlacklists', { initialValue: [] }]"
                        placeholder="Please select IP Lists" :showSearch="true" :filterOption="true" mode="multiple"
                        optionFilterProp="children">
                        <a-select-option v-for="obj in activeIpLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Supply Categories Filter</a-divider>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Enable IAB Categories">
                <a-tree checkable v-model="checkedKeys" :auto-expand-parent="autoExpandParent"
                  :selected-keys="selectedKeys" :tree-data="treeData" @expand="onExpand" @select="onSelect"
                  optionFilterProp="children" />
              </a-form-item>
              <a-divider class="mt-3"></a-divider>
              <a-divider style="margin-top: 34px;">Targeting</a-divider>
              <a-tabs default-active-key="1" class="mb-4">
                <a-tab-pane key="1" tab="Countries">
                  <a-row>
                    <a-input-search placeholder="Filter Countries" style="width: 200px" class="mb-2"
                      v-model="filterSearch" />
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half"
                        v-model="displayEnabledCountries" /> Display
                      Only Enabled</label>
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half" v-model="selectAllCountries"
                        @click="handleCountries" /> De/Select All</label>
                  </a-row>
                  <a-spin class="d-flex justify-content-center" v-if="enabledCountries.length === 0">
                    <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                  </a-spin>
                  <ul v-else class="list-group list-group-flush countryList" style="border: 1px solid #e4e9f0;">
                    <li class="list-group-item treeContainer" v-for="(item, index) in enabledCountries" :key="index"
                      :style="item.Enabled ? 'background-color: rgb(237 237 243 / 38%);' : ''">
                      <label class="col-sm cpos cpointer">
                        <input type="checkbox" class="global-checkbox" v-model="item.Enabled"
                          @click="enableCountry($event, item)" />
                        <span class="col-sm"><img :src="matchIcon(countries, item.CC)" /> {{ item.Country }} ({{
              item.CC
            }})</span>
                      </label>
                    </li>
                  </ul>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Operating Systems" force-render>
                  <treeListRtb :listData="OSData" type="os" v-model="OSData" :defaultVal="100">
                  </treeListRtb>
                </a-tab-pane>
                <a-tab-pane key="3" class="" style="overflow-x: scroll; overflow-y:hidden" tab="Browsers" force-render>
                  <treeListRtbCreate :listData="BrowserData" type="browser" v-model="BrowserData" :defaultVal="100">
                  </treeListRtbCreate>
                </a-tab-pane>
                <a-tab-pane key="4" tab="Device Types">
                  <ul class="list-group list-group-flush countryList" style="border: 1px solid #e4e9f0;">
                    <li v-for="item in deviceTypes" :key="item.index" class="list-group-item treeContainer"
                      :style="activeDeviceTypes.includes(item.value) ? 'background-color: rgb(237 237 243 / 38%);' : ''">
                      <label class="col-sm cpos cpointer">
                        <input type="checkbox" class="global-checkbox" :checked="enabledDevice(item.value)"
                          @click="enableDevice($event, item.value)" />
                        <span class="col-sm">{{ item.name }}</span>
                      </label>
                    </li>
                  </ul>
                </a-tab-pane>
              </a-tabs>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Create DSP Pop Feed</button>
                  <button type="button" @click="$router.push({ name: 'dsppopfeeds' })"
                    class="btn btn-light px-5 ml-2">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { getIabCategories, getOSs } from '@/api/info'
import treeListRtbCreate from '@/components/custom/TreeListRtbCreate'
import treeListRtb from '@/components/custom/TreeListRtb'
import staticCountryData from '@/resources/countries.json'
import Vue from 'vue'

export default {
  components: { treeListRtbCreate, treeListRtb },
  computed: {
    ...mapState(['advertiser', 'info', 'remotertbzone', 'dsppopfeed']),
    ...mapGetters('ORTBiplist', ['getIpLists', 'getActiveIpLists']),
    ...mapGetters('domainlist', ['getDomainLists', 'getActiveDomainLists']),
    activeIpLists: function () {
      return this.getActiveIpLists.filter(el => el.Global === 0)
    },
    activeDomainLists: function () {
      return this.getActiveDomainLists.filter(el => el.Global === 0)
    },
    pubIdListString() {
      return this.pubIdList.join('\n')
    },
    enabledCountries() {
      let filteredCountries = this.countriesList
      if (this.displayEnabledCountries) {
        filteredCountries = filteredCountries.filter(el => el.Enabled)
      }
      if (this.filterSearch) {
        filteredCountries = filteredCountries.filter(el =>
          el.Country.toLowerCase().includes(this.filterSearch.toLowerCase()) || el.CC.toLowerCase().includes(this.filterSearch.toLowerCase()),
        )
      }
      return filteredCountries
    },
    initialValue() {
      if (this.$route.params.advertiserId) {
        const advertiser = this.advertiser.list.find(ele => ele.Id === parseInt(this.$route.params.advertiserId))
        return advertiser === undefined ? [] : advertiser.Id
      } else {
        return []
      }
    },
  },
  data() {
    return {
      filterSearch: '',
      pubIdList: [],
      unformattedResponse: [],
      treeData: [],
      selectedKeys: [],
      autoExpandParent: false,
      checkedKeys: [],
      nonEmptyRefererEnabled: true,
      autoOpt: true,
      refererMatchEnabled: true,
      ipMatchEnabled: true,
      uaMatchEnabled: true,
      BrowserData: [],
      OSData: [],
      countries: staticCountryData,
      displayEnabledCountries: false,
      form: this.$form.createForm(this),
      countriesList: [],
      selectAllCountries: true,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      labelColUrl: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperColUrl: {
        xs: { span: 24 },
        sm: { span: 21 },
      },
      deviceTypes: [
        {
          name: 'Desktop',
          value: 1,
        },
        {
          name: 'Mobile',
          value: 2,
        },
        {
          name: 'Tablet',
          value: 3,
        },
        {
          name: 'CTV',
          value: 4,
        },
        {
          name: 'Other',
          value: 5,
        },
      ],
      activeDeviceTypes: [1, 2, 3, 4, 5],
    }
  },
  methods: {
    copyList(type) {
      if (type === 'pubIds') {
        navigator.clipboard.writeText(this.pubIdList)
        Vue.prototype.$notification.success({
          message: 'Pub ID List copied to clipboard.',
        })
      } else if (type === 'crids') {
        navigator.clipboard.writeText(this.cridList)
        Vue.prototype.$notification.success({
          message: 'CRID List copied to clipboard.',
        })
      }
    },
    enableDevice(event, value) {
      const index = this.activeDeviceTypes.indexOf(value)
      if (index === -1) {
        this.activeDeviceTypes.push(value)
      } else {
        this.activeDeviceTypes.splice(index, 1)
      }
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) {
        return false
      } else {
        const image = cc.image
        return image
      }
    },
    $_formatTreeData(type = 'os') {
      const searchData = type === 'os' ? this.OSData : this.BrowserData
      const formatedData = []
      searchData.forEach(el => {
        // Add if all versions selected
        if (el.enabled) {
          const temp = {
            Family: el.Family,
            BidAdjustment: 100,
          }
          if (el.Versions.length > 0) {
            const tmpV = []
            el.Versions.forEach(ss => {
              if (ss.enabled) {
                const temp2 = {
                  BidAdjustment: 100,
                  Major: ss.Major,
                }
                if (typeof ss.Minor !== 'undefined') temp2.Minor = ss.Minor
                tmpV.push(temp2)
              }
            })
            temp.Versions = tmpV
          }
          if (typeof el.AutoIncludeNewBrowsers !== 'undefined') temp.AutoIncludeNewBrowsers = el.AutoIncludeNewBrowsers ? 1 : 0
          formatedData.push(temp)
          return
        }
        // Filter versions
        const versions = []
        el.Versions.length > 0 && el.Versions.forEach(ver => {
          if (ver.enabled) {
            const temp = {
              BidAdjustment: 100,
              Major: ver.Major,
            }
            if (typeof ver.Minor !== 'undefined') temp.Minor = ver.Minor
            versions.push(temp)
          }
        })
        // Add if one or more versions is used
        if (versions.length > 0) {
          const tmp = {
            Family: el.Family,
            BidAdjustment: 100,
            Versions: versions,
          }
          if (typeof el.AutoIncludeNewBrowsers !== 'undefined') tmp.AutoIncludeNewBrowsers = el.AutoIncludeNewBrowsers ? 1 : 0
          formatedData.push(tmp)
        }
      })
      return formatedData
    },
    handleCountries() {
      this.selectAllCountries = !this.selectAllCountries
      this.countriesList.forEach(el => {
        el.Enabled = this.selectAllCountries
      })
    },
    enabledDevice(value) {
      if (this.activeDeviceTypes.includes(value)) {
        return true
      } else {
        return false
      }
    },
    enableCountry(event, row) {
      const itemIndex = this.countriesList.findIndex(el => el.CC === row.CC)
      if (itemIndex !== -1) {
        this.countriesList[itemIndex].Enabled = event.target.checked
      }
    },
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys
    },
    onSelect(selectedKeys, info) {
      // console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    },
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    populateCountry: function () {
      if (this.countriesList.length > 0 && this.countriesList !== null && this.countriesList.length > 0) {
        for (const index in this.countriesList) {
          const country = this.countriesList.filter(ob => ob.CC === this.countriesList[index].CC)
          if (typeof country[0] !== 'undefined') {
            this.countriesList[index].Enabled = true
          }
        }
      }
    },
    handleSubmit() {
      const countries = []
      this.countriesList.forEach(el => {
        if (el.Enabled) {
          countries.push({
            CC: el.CC,
            BidAdjustment: 100,
          })
        }
      })
      const oss = this.$_formatTreeData('os')
      const browsers = this.$_formatTreeData('browser')
      this.form.validateFields((err, values) => {
        if (!err) {
          values.PubIdList = this.pubIdListString
          values.AdAppsBlacklist = this.adAppsBlacklistString
          values.NonEmptyReferer = this.nonEmptyRefererEnabled ? 1 : 0
          values.RefererMatch = this.refererMatchEnabled ? 1 : 0
          values.IpMatch = this.ipMatchEnabled ? 1 : 0
          values.UaMatch = this.uaMatchEnabled ? 1 : 0
          values.AutoOptimize = this.autoOpt ? 1 : 0
          values.DeviceTypes = this.activeDeviceTypes
          if (values.feedUrl !== '' && values.feedUrl) {
            values.feedUrl = values.feedUrl.replace(/ /g, '')
          }
          const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          const newArr = this.checkedKeys.filter(el => !array.includes(el))
          values.IabCategories = newArr
          this.$store.dispatch('dsppopfeed/CREATE_FEED', {
            payload: {
              ...values,
              // DefaultCPM: 1,
              Browsers: browsers,
              Os: oss,
              Countries: countries,
            },
          }).then(response => {
            const id = response.Dsppopfeed.Id
            this.$router.push({ name: 'dsppopfeed-update', params: { id } })
          }).catch(error => {
            console.error('Error creating DSP Pop Feed:', error)
          })
        }
      })
    },
    cpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Value can not be negative.')
      }
      message()
    },
    impressionsValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 1) {
        message('Value can not be less than 1.')
      }
      message()
    },
  },
  created() {
    getIabCategories().then(response => {
      const resultArray = response.flatMap(obj => {
        const subnameArray = obj.Subcategories.map(subname => subname.Name)
        return [obj.Name, ...subnameArray]
      }).filter(name => name !== undefined)
      this.checkedKeys = resultArray
      response.forEach(element => {
        this.unformattedResponse.push(element.Name)
      })
      const formattedResponse = []
      response.forEach((el, id) => {
        const temp = {
          title: '(' + el.Name + ')' + ' ' + el.Title,
          key: el.Name,
          children: [],
        }
        el.Subcategories.forEach((element) => {
          temp.children.push({
            title: '(' + element.Name + ')' + ' ' + element.Title,
            key: element.Name,
          })
        })
        formattedResponse.push(temp)
        this.treeData = formattedResponse
      })
    })
    this.$store.dispatch('info/LOAD_COUNTRIES').then((res) => {
      res.forEach((el) => {
        this.countriesList.push({
          Country: el.Country,
          CC: el.CC,
          Enabled: false,
        })
      })
      this.populateCountry()
    })
    this.$store.dispatch('advertiser/LOAD_ADVERTISERS_SHORT')
    this.$store.dispatch('ORTBiplist/LOAD_IPLISTS')
    this.$store.dispatch('domainlist/LOAD_DOMAINLISTS')
    this.$store.dispatch('info/LOAD_OSS').then((res) => {
      if (!res) {
        getOSs().then(res => {
          this.OSData = res
        })
      } else {
        this.OSData = this.info.oss
      }
    })
    this.$store.dispatch('info/LOAD_BROWSERS').then((res) => {
      if (!res) {
        getOSs().then(res => {
          this.browserData = res
        })
      } else {
        this.browserData = this.info.browsers
      }
    })
  },
}
</script>
<style lang="css" scoped>
.ant-select-dropdown-menu-item-selected.zones {
  color: #595c97 !important;
  font-weight: 600 !important;
  display: none !important;
}

.list-flex {
  display: flex;
  align-content: flex-start;
}

.select-width {
  width: 90%;
}

.copy-button-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem !important;
  height: 2.1rem !important;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.custom-table th,
.custom-table td {
  padding: 0 0 0 6px !important;
  height: 30;
  text-align: center;
}

.no-records-to-show {
  text-align: center;
  text-decoration: none;
  font-weight: normal;
}

.ant-table-small {
  border: none !important;
}

/** */
.arrow.left::before {
  content: "▶";
  height: 10px;
  margin-right: 5px;
}

.arrow.down::before {
  content: "▼";
  height: 10px;
  margin-right: 5px;
}

.bid-adjustment {
  display: inline;
  width: 80px !important;
  padding: 0 5px;
  font-size: 0.9rem;
  height: 1.7rem;
}

.checkbox {
  position: relative;
  top: 2px;
}

.list-group-item {
  padding: .1rem 1.25rem !important;
}

.treeContainer {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.treeContainerV {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  /* flex-wrap: wrap; */
}

label {
  opacity: 0.8;
}

.no-border {
  border: none;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 1px !important;
}

.global-checkbox {
  position: relative;
  top: 2px;
}

.cpos-half {
  position: relative;
  top: 2px;
}

.cpos {
  position: relative;
  top: 4px;
}

.version-checkbox {
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.no-margin {
  margin-bottom: 0;
}

.cpointer {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }

  .mt-10 {
    margin-top: 3.76rem;
  }
}

@media only screen and (max-width: 768px) {
  .-m {
    margin-right: -4rem;
  }

  .break-line>>>li .ant-tree-node-content-wrapper {
    margin-bottom: 5px;
    padding-top: 4px;
    display: inline-block !important;
    white-space: pre-wrap !important;
    line-height: 15px !important;
    width: 90% !important;
  }
}
</style>
